import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import abi from './abi.json';
import './App.css';

const FREELANCE_PLATFORM_ADDRESS = '0x5d04c04f90F4862Ea6aaCfb19B768D52FA8e507d';
const FREELANCE_PLATFORM_ABI = abi;

function App() {
  const [name, setName] = useState('');
  const [skills, setSkills] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [budget, setBudget] = useState('');
  const [deadline, setDeadline] = useState('');

  const [connected, setConnection] = useState(false);
  const [ensName, setEns] = useState('');

  const [register, setRegister] = useState(false);
  const [create, setCreate] = useState(false);
  const [complete, setComplete] = useState(false);
  const [rate, setRate] = useState(false);
  const [stats, setStats] = useState(false);
  const [info, setInfo] = useState(false);
  const [alert, setAlert] = useState(false);

  const [freelancerName, setFreelancerName] = useState("");
  const [freelancerSkills, setFreelancerSkills] = useState("");
  const [freelancerHourlyRate, setFreelancerHourlyRate] = useState("");
  const [freelancerTotalRatings, setFreelancerTotalRatings] = useState("");
  const [freelancerRating, setFreelancerRating] = useState("");

  const [bountyName, setBountyName] = useState("");
  const [bountyDescription, setBountyDescription] = useState("");
  const [bountyBudget, setBountyBudget] = useState("");
  const [bountyClient, setBountyClient] = useState("");
  const [bountyDeadline, setBountyDeadline] = useState("");
  const [bountyCompleted, setBountyCompleted] = useState("");
  const [setBounty, getBounty] = useState(false);
  const [clientAddress, setClientAddress] = useState("");
  const [projects, setProjects] = useState([]);
  const [freelancers, setFreelancers] = useState([]);

  useEffect(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner()
    const contract = new ethers.Contract(FREELANCE_PLATFORM_ADDRESS, FREELANCE_PLATFORM_ABI, signer);

    const filter = contract.filters.FreelancerRegistered();

    const fetchFreelancers = async () => {
      const events = await contract.queryFilter(filter);

      const newFreelancers = events.map((event) => {
        const freelancerAddress = event.args[0];
        return contract.getFreelancer(freelancerAddress);
      });

      Promise.all(newFreelancers).then((results) => {
        setFreelancers(results);
      });
    };

    fetchFreelancers();
  }, []);

  async function getProjects() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner()
    const contract = new ethers.Contract(FREELANCE_PLATFORM_ADDRESS, FREELANCE_PLATFORM_ABI, signer);
    const projectCount = await contract.projectCount();
    const projects = [];
    for (let i = 0; i < projectCount; i++) {
      const project = await contract.projects(i);
      projects.push(project);
    }
    setProjects(projects);
    console.log(projects.id)
  }

  useEffect(() => {
    getProjects();
  }, []);

  function getRegister() {
    setRegister(true);
    setComplete(false);
    setCreate(false);
    setRate(false);
    setStats(false);
    setInfo(false);
  }

  function getCreate() {
    setRegister(false);
    setComplete(false);
    setCreate(true);
    setRate(false);
    setStats(false);
    setInfo(false);
  }

  function getComplete() {
    setRegister(false);
    setComplete(true);
    setCreate(false);
    setRate(false);
    setStats(false);
    setInfo(false);
  }

  function getRate() {
    setRegister(false);
    setComplete(false);
    setCreate(false);
    setRate(true);
    setStats(false);
    setInfo(false);
  }

  function getStats() {
    setRegister(false);
    setComplete(false);
    setCreate(false);
    setRate(false);
    setStats(true);
    setInfo(false);
  }

  function getInfo() {
    setRegister(false);
    setComplete(false);
    setCreate(false);
    setRate(false);
    setStats(false);
    setInfo(true);
  }

  function closeStats() {
    setAlert(false)
  }

  const sign = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      await signer.signMessage("Welcome to Mandalor");

      const { ethereum } = window;
      if (ethereum) {
        const ensProvider = new ethers.providers.InfuraProvider('mainnet');
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const displayAddress = address?.substr(0, 6) + "...";
        const ens = await ensProvider.lookupAddress(address);
        if (ens !== null) {
          setEns(ens)
        } else {
          setEns(displayAddress)
        }
      }

    } catch {
      console.log('error')
    }
    setConnection(true)
    setRegister(true)
  }

  async function registerFreelancer() {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const contract = new ethers.Contract(FREELANCE_PLATFORM_ADDRESS, FREELANCE_PLATFORM_ABI, signer);
      const tx = await contract.registerFreelancer(name, skills.split(',').map(skill => skill.trim()), hourlyRate);
      await tx.wait();
    } catch {
      console.log('error')
    }

  }

  async function createProject() {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(FREELANCE_PLATFORM_ADDRESS, FREELANCE_PLATFORM_ABI, signer);
      const budgetWei = ethers.utils.parseEther(budget);
      const deadlineTimestamp = Math.floor(Date.parse(deadline) / 1000);
      const tx = await contract.createProject(title, description, budgetWei, deadlineTimestamp, { value: budgetWei });
      await tx.wait();
      const id = await contract.on("ProjectCreated", (projectId) => {
        console.log(`${projectId}`);
      });
      alert("success")
      console.log(id)
    } catch {
      console.log('error')
    }
  }

  async function completeProject(projectId) {
    console.log('projectId:', projectId);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(FREELANCE_PLATFORM_ADDRESS, FREELANCE_PLATFORM_ABI, signer);
    const tx = await contract.completeProject(projectId);
    await tx.wait();
  }

  async function rateFreelancer(freelancerAddress, rating) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(FREELANCE_PLATFORM_ADDRESS, FREELANCE_PLATFORM_ABI, signer);
    const tx = await contract.rateFreelancer(freelancerAddress, rating);
    await tx.wait();
  }

  async function getProject(projectId) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(FREELANCE_PLATFORM_ADDRESS, FREELANCE_PLATFORM_ABI, provider);
    const project = await contract.getProject(projectId);
    const budget = ethers.utils.formatEther(project.budget);
    const deadline = ethers.utils.formatEther(project.deadline);

    const { ethereum } = window;
    if (ethereum) {
      const retrieveAddress = await project.client;
      const ensProvider = new ethers.providers.InfuraProvider('mainnet');
      const displayAddress = retrieveAddress?.substr(0, 6) + "...";
      const ens = await ensProvider.lookupAddress(retrieveAddress);
      if (ens !== null) {
        setClientAddress(ens)
      }
      setClientAddress(displayAddress)
    }

    const check = await project.completed
    if (check === true) {
      setBountyCompleted('Yes')
    }
    if (check === false) {
      setBountyCompleted('No')
    }
    setBountyName(project.title)
    setBountyBudget(budget)
    setBountyClient(clientAddress)
    setBountyDescription(project.description)
    setBountyDeadline(deadline)
    getBounty(true)
    console.log(project.title)
    return project;
  }

  function closeBounty() {
    getBounty(false)
  }

  console.log('projects:', projects);

  return (
    <div className='main'>
      {alert && (
        <div className='bountyHunter'>
          <h3>Bounty Hunter: {freelancerName}</h3>
          <h3>Stats: {freelancerSkills}</h3>
          <h3>Rate in ETH: {freelancerHourlyRate}</h3>
          <h3>Rating: {freelancerRating}</h3>
          <h3>Total Ratings: {freelancerTotalRatings}</h3>
          <button style={{ position: "absolute", top: "10px", right: "10px", padding: "0px 5px", borderRadius: "px" }} onClick={closeStats}><strong>X</strong></button>
        </div>
      )}
      {setBounty && (
        <div className='bountyHunter'>
          <h3>Bounty: {bountyName}</h3>
          <h3>Budget: {bountyBudget}</h3>
          <h3>Client: {bountyClient}</h3>
          <h3>Description: {bountyDescription}</h3>
          <h3>Deadline: {bountyDeadline}</h3>
          <h3>Completed: {bountyCompleted}</h3>
          <button style={{ position: "absolute", top: "10px", right: "10px", padding: "0px 5px", borderRadius: "px" }} onClick={closeBounty}><strong>X</strong></button>
        </div>
      )}
      <div className='header'>
        <h1 style={{ fontSize: "400%", fontFamily: "monospace" }}>Mandalor Protocol</h1>
        <h3 style={{ fontFamily: "monospace", fontSize: "200%", marginTop: "-30px" }}>-A Web3 Bounty Board-</h3>
      </div>
      {!connected && (
        <div className='signBtn'>
          <button className='sign' onClick={sign}>sign in</button>
        </div>
      )}
      {connected && (
        <div>
          <div className='userName'>
            {ensName}
          </div>

          <div className='nav'>
            <div onClick={getRegister}><h2>Register</h2></div>
            <div onClick={getCreate}><h2>Create Bounty</h2></div>
            <div onClick={getComplete}><h2>Bounty Board</h2></div>
            <div onClick={getRate}><h2>Rate Bounty Hunter</h2></div>
            <div onClick={getStats}><h2>Bounty Hunter Stats</h2></div>
            <div onClick={getInfo}><h2>Bounty Information</h2></div>
          </div>

          {register && (
            <div className='register'>
              <div>
                <h2>Register Bounty Hunter</h2>
              </div>
              <div>
                <form onSubmit={(event) => { event.preventDefault(); registerFreelancer(); }}>
                  <div>
                    <label>
                      Name:
                      <input type="text" value={name} onChange={(event) => { setName(event.target.value); }} />
                    </label>
                  </div>
                  <div>
                    <label>
                      Skills:
                      <input type="text" value={skills} onChange={(event) => { setSkills(event.target.value); }} />
                    </label>
                  </div>
                  <div>
                    <label>
                      Hourly rate (in ETH):
                      <input type="text" value={hourlyRate} onChange={(event) => { setHourlyRate(event.target.value); }} />
                    </label>
                  </div>
                  <div style={{ width: "50%", margin: "auto", marginTop: "10px", textAlign: "center" }}>
                    <button type="submit">Register</button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {create && (
            <div className='create'>
              <div>
                <h2>Create Bounty</h2>
              </div>
              <div>
                <form onSubmit={(event) => { event.preventDefault(); createProject(); }}>
                  <div>
                    <label>
                      Title:
                      <input type="text" value={title} onChange={(event) => { setTitle(event.target.value); }} />
                    </label>
                  </div>
                  <div>
                    <label>
                      Description:
                      <textarea value={description} onChange={(event) => { setDescription(event.target.value); }}></textarea>
                    </label>
                  </div>
                  <div>
                    <label>
                      Budget (in ETH):
                      <input type="text" value={budget} onChange={(event) => { setBudget(event.target.value); }} />
                    </label>
                  </div>
                  <div>
                    <label>
                      Deadline:
                      <input type="datetime-local" value={deadline} onChange={(event) => { setDeadline(event.target.value); }} />
                    </label>
                  </div>
                  <div style={{ width: "50%", margin: "auto", marginTop: "10px", textAlign: "center" }}>
                    <button type="submit">Create Bounty</button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {complete && (
            <div className='complete'>
              <div>
                <h2>Bounty Board</h2>
              </div>
              <div>
                {projects.slice().reverse().map((project, index) => {
                  if (project.completed) {
                    return null; // don't render the project if it's completed
                  }
                  return (
                    <div className='bounties' key={project.id} style={{ display: project.completed ? 'none' : 'block' }}>
                      <h2>Bounty: {project.title}</h2>
                      <p>Description: {project.description}</p>
                      <p>Budget: {ethers.utils.formatEther(project.budget.toString())} ETH</p>
                      <button className='claim' onClick={() => {
                        completeProject(projects.length - 1 - index);
                      }}>Claim</button>
                    </div>
                  );
                })}
              </div>

            </div>
          )}

          {rate && (
            <div className='rate'>
              <div>
                <h2>Rate Bounty Hunter</h2>
              </div>
              <div>
                <form onSubmit={(event) => { event.preventDefault(); rateFreelancer(event.target.elements.freelancerAddress.value, event.target.elements.rating.value); }}>
                  <div>
                    <label>
                      Bounty Hunter Address: &nbsp;
                      <input type="text" name="freelancerAddress" />
                    </label>
                  </div>
                  <div>
                    <label>
                      Rating (1-5): &nbsp;
                      <input type="number" name="rating" min="1" max="5" />
                    </label>
                  </div>
                  <div style={{ width: "50%", margin: "auto", marginTop: "10px", textAlign: "center" }}>
                    <button type="submit">Rate Bounty Hunter</button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {stats && (
            <div className='info'>
              <div>
                <h2>Bounty Hunters</h2>
              </div>
              <div>
                {freelancers.map((freelancer) => {
                  const name = freelancer[0];
                  const skills = freelancer[1];
                  const hourlyRate = ethers.utils.formatEther(freelancer[2].mul(ethers.utils.parseEther("1")));
                  const rating = ethers.utils.formatEther(freelancer[3].mul(ethers.utils.parseEther("1")));
                  const numRatings = ethers.utils.formatEther(freelancer[4].mul(ethers.utils.parseEther("1")));

                  return (
                    <div className='bountyHunters' key={name}>
                      <h2>{name}</h2>
                      <p>Skills: {skills}</p>
                      <p>Hourly Rate: {hourlyRate.toString()}</p>
                      <p>Rating: {rating.toString()}</p>
                      <p>Number of Ratings: {numRatings.toString()}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {info && (
            <div className='bounty'>
              <div>
                <h2>Bounty Information</h2>
              </div>
              <div>
                <form onSubmit={(event) => { event.preventDefault(); getProject(event.target.elements.projectId.value).then(project => alert(JSON.stringify(project))); }}>
                  <div>
                    <label>
                      Bounty ID: &nbsp;
                      <input type="text" name="projectId" />
                    </label>
                  </div>
                  <div style={{ width: "50%", margin: "auto", marginTop: "10px", textAlign: "center" }}>
                    <button type="submit">Get Bounty</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}

    </div>
  );
}
export default App;